import { toast } from "react-hot-toast";
import axiosConfig from "../lib/axiosConfig";

export const useAuth = () => {
    const csrf = () => axiosConfig.get('/sanctum/csrf-cookie');

    const getUser = async ({ setLoading, setUser, setToken }) => {
        setLoading(true);
        axiosConfig
            .get('/api/get-user')
            .then(({ data }) => setUser(data.data))
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Error al consultar los datos del usuario.')
                console.log(response);
                setUser(null);
                setToken(null);
            })
            .finally(() => setLoading(false))
    }

    const login = async ({ setLoading, data }) => {
        setLoading(true);
        await csrf();

        axiosConfig
            .post('/api/login', data)
            .then(({ data }) => {
                toast.success(data?.message);
                localStorage.setItem('u_t', data?.token);
                setTimeout(() => {
                    window.location = '/'
                }, 500);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || JSON.stringify(response));
            })
            .finally(() => setLoading(false))
    }

    const logout = ({setLoading, setToken}) => {
        setLoading(true);

        axiosConfig
            .post('/api/logout')
            .then(({data}) => {
                toast.success(data?.message);
                setToken(null);
                localStorage.removeItem('u_t');
                setTimeout(() => {
                    window.location = '/login'
                }, 500);
            })
            .catch(({response}) => {
                toast.error('Error al cerrar sesión.');
                console.log(response);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return {
        login,
        getUser,
        logout
    }
}