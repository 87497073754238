import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Spinner from "./components/spinner/Spinner";
import ScrollTop from "./components/ScrollTop";
import { useStateContext } from "./context/ContextProvider";
import { useAuth } from "./hooks/useAuth";
import { useRoutes } from "react-router-dom";
import Routes from "./router/Routes.js";
import ThemeCustomization from "./themes";

function App() {
    const [loading, setLoading] = useState(false);

    const { token, setUser, setToken } = useStateContext();
    const { getUser } = useAuth();
    const router = useRoutes(Routes());

    useEffect(() => {
        if (token) getUser({ setLoading, setUser, setToken });
    }, []);

    useEffect(() => {
        document.title = process.env.REACT_APP_TITLE;
    },[]);

    return (
        <ThemeCustomization>
            {
                loading ?
                    <Grid container spacing={0} justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={12} textAlign={'center'} sx={{ mt: { xs: 25, md: 40 } }}>
                            <Spinner />
                        </Grid >
                    </Grid > :
                    <ScrollTop>
                        {router}
                    </ScrollTop>
            }
        </ThemeCustomization>
    );
}

export default App;
