import React, { lazy } from "react";
import Loadable from "../components/Loadable";
import AppLayout from "../layout/AppLayout";

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard/Dashboard')));
const Home = Loadable(lazy(() => import('../pages/Home/Home')));
const User = Loadable(lazy(() => import('../pages/User/User')));
const Rol = Loadable(lazy(() => import('../pages/Rol/Rol')));
const Variable = Loadable(lazy(() => import('../pages/Variable/Variable')));
const Employee = Loadable(lazy(() => import('../pages/Employee/Employee')));
const Office = Loadable(lazy(() => import('../pages/Office/Office')));
const Report = Loadable(lazy(() => import('../pages/Report/Report')));
const Profile = Loadable(lazy(() => import('../pages/Profile/Profile')));
const TempEmployee = Loadable(lazy(() => import('../pages/TempEmployee/TempEmployee')));

const NotFound = Loadable(lazy(() => import('../pages/NotFound')));

const Routes = () => {

    return [
        {
            path: '/',
            element: <AppLayout />,
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: '/dashboard',
                    element: <Dashboard />
                },
                {
                    path: '/user',
                    element: <User />
                },
                {
                    path: '/rol',
                    element: <Rol />
                },
                {
                    path: '/variable',
                    element: <Variable />
                },
                {
                    path: '/employee',
                    element: <Employee />
                },
                {
                    path: '/temp-employee',
                    element: <TempEmployee />
                },
                {
                    path: '/office',
                    element: <Office />
                },
                {
                    path: '/report',
                    element: <Report />
                },
                {
                    path: '/profile',
                    element: <Profile />
                }
            ]
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '*',
            element: <NotFound />
        }
    ];
}

export default Routes;