import React, { useState } from 'react'
import { Link, Outlet, Link as RouteLink } from 'react-router-dom'
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { Avatar, Box, Container, CssBaseline, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ChevronLeftSharp, LogoutSharp, MenuSharp, PersonSharp, SettingsSharp } from '@mui/icons-material';
import styled from '@emotion/styled';
import { adminMenu, menu } from '../menu-items/menu';
import Logo from '../assets/logo.svg';
import DefaultUser from '../assets/user.svg';
import { useStateContext } from '../context/ContextProvider';
import { useAuth } from '../hooks/useAuth';
import { TitleContext } from '../context/TitleContext';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const AppLayout = () => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('Home');
    const openMenu = Boolean(anchorEl);
    const match = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { user, setToken } = useStateContext();
    const { logout } = useAuth();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        logout({ setLoading, setToken });
    }

    return (
        <>
            <TitleContext.Provider value={{ title, setTitle }}>
                <Box sx={{ display: 'flex', backgroundColor: 'secondary.lighter', color: 'secondary.darker' }}>
                    <CssBaseline />
                    <AppBar position="absolute" open={open} color='primary' elevation={0}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            {
                                !match &&
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer}
                                    sx={{
                                        marginRight: '36px',
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    <MenuSharp />
                                </IconButton>
                            }

                            <Typography
                                textAlign={'center'}
                                component="h1"
                                variant="h4"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                {title}
                            </Typography>
                            <Tooltip title="Cuenta">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar
                                        sx={{ width: 32, height: 32 }}
                                        src={user?.profile?.avatar ? `${process.env.REACT_APP_API_URL}/storage/users/${user.profile.avatar}` : DefaultUser}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                px: [1],
                                backgroundColor: 'primary.main'
                            }}
                        >
                            <Avatar
                                variant='square'
                                src={Logo}
                                sx={{
                                    mx: 'auto'
                                }}
                            />
                            <IconButton onClick={toggleDrawer} >
                                <ChevronLeftSharp sx={{ color: '#fff' }} />
                            </IconButton>
                        </Toolbar>
                        {/* <Divider /> */}
                        <List component="nav" sx={{ height: '100%', backgroundColor: 'primary.main', color: 'secondary.lighter' }}>
                            {/* {mainListItems}
                        
                        {secondaryListItems} */}
                            {
                                menu.map((item, index) => (
                                    <Tooltip key={index} title={item.text} placement="right">
                                        <ListItemButton component={RouteLink} to={item.url} sx={{ px: { xs: 2, md: 3 } }}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} sx={{ ml: { xs: 2, md: 3 } }} />
                                        </ListItemButton>
                                    </Tooltip>
                                ))
                            }
                            {/* <Divider sx={{ my: 1 }} /> */}
                            {
                                adminMenu.map((item, index) => (
                                    <Tooltip key={index} title={item.text} placement="right">
                                        <ListItemButton component={RouteLink} to={item.url} sx={{ px: { xs: 2, md: 3 } }}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} sx={{ ml: { xs: 2, md: 3 } }} />
                                        </ListItemButton>
                                    </Tooltip>
                                ))
                            }
                        </List>
                    </Drawer>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth={false} sx={{ my: 2 }}>
                            <Outlet />
                        </Container>
                    </Box>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleClose}
                    onClick={handleClose}
                    sx={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem component={Link} to={'/profile'} disabled={loading}>
                        <PersonSharp /> {user?.profile?.first_name || user?.name}
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} disabled={loading}>
                        <SettingsSharp />Configuración
                    </MenuItem> */}
                    <MenuItem onClick={handleLogOut} disabled={loading}>
                        <LogoutSharp />Cerrar sesión
                    </MenuItem>
                </Menu>
            </TitleContext.Provider>
        </>
    )
}

export default AppLayout