import axios from 'axios';

const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('u_t')}`,
        'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true
});

export default axiosConfig;
