import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ContextProvider } from './context/ContextProvider.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <ContextProvider>
            <App />
            <Toaster />
        </ContextProvider>
    </BrowserRouter>
);