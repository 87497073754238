import { AlarmSharp, AssessmentSharp, BadgeSharp, HomeSharp, PeopleAltSharp, SecuritySharp, SettingsSharp, StoreSharp } from "@mui/icons-material";

export const menu = [
    {
        url: '/',
        text: 'Inicio',
        icon: <HomeSharp sx={{ color: 'secondary.lighter' }} />
    },
    {
        url: '/employee',
        text: 'Colaboradores',
        icon: <BadgeSharp sx={{ color: 'secondary.lighter' }} />
    },
    {
        url: '/temp-employee',
        text: 'Col. Temporales',
        icon: <AlarmSharp sx={{ color: 'secondary.lighter' }} />
    },
    {
        url: '/office',
        text: 'Sucursales',
        icon: <StoreSharp sx={{ color: 'secondary.lighter' }} />
    },
    {
        url: '/report',
        text: 'Reportes',
        icon: <AssessmentSharp sx={{ color: 'secondary.lighter' }} />
    }
]

export const adminMenu = [
    {
        url: '/user',
        text: 'Usuarios',
        icon: <PeopleAltSharp sx={{ color: 'secondary.lighter' }} />
    },
    {
        url: '/rol',
        text: 'Roles',
        icon: <SecuritySharp sx={{ color: 'secondary.lighter' }} />
    },
    {
        url: '/variable',
        text: 'Variables',
        icon: <SettingsSharp sx={{ color: 'secondary.lighter' }} />
    }
]